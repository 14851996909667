import React, { useState, useEffect } from "react";
import "../styles/home.css";
import treeman from "../images/treeman.jpg";
import gardener from "../images/gardener.png";
import decoration from "../images/decoration.png";
import dustbin from "../images/dustbin.png";
import tree from "../images/tree.png";
import warning from "../images/warning.png";
import { Link, useNavigate } from "react-router-dom";
import { firestore } from "../firebase/firebase.js";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "../styles/modal.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Home() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Fetch reviews and limit to 10
    const fetchReviews = async () => {
      try {
        const reviewCollection = firestore.collection("reviews");
        const reviewSnapshot = await reviewCollection
          .orderBy("timestamp", "desc")
          .limit(10) // Limit reviews to 10
          .get();
        const reviewData = reviewSnapshot.docs.map((doc) => doc.data());
        setReviews(reviewData);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    fetchReviews();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const ref = firestore.collection("Registered users");
    let data = {
      name,
      email,
      contact,
      createdAt: new Date(),
    };

    try {
      ref.add(data);
      setModalIsOpen(true);
    } catch (err) {
      console.error("Failed to add user:", err);
  alert("There was a problem with registration. Please try again.");
    }
    navigate("/");
  }

  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhone = (e) => setContact(e.target.value);

  return (
    <>
      <div className="home">
        <div className="home-cont">
          <h3 id="change-color" className="line-1">
            STAY CLEAN
          </h3>
          <h3 id="change-color2" className="line-2">
            GO GREEN
          </h3>
          <p id="change-color3" className="line-3">
            Do You Have Waste Around You?
          </p>
          <Link to="/orderpage" className="din-btn">
            DUMP IT NOW ?
          </Link>
        </div>

        <div className="div-1">
          <p>Become A Recycling Hero</p>
        </div>
      </div>

      <div className="div-2">
        <div className="img-cont">
          <img src={treeman} alt="tree" />
        </div>

        <div className="about-div">
          <h3 id="about" className="about">
            About Us
          </h3>
          <p>
            We at Dump It Now are giving our all efforts to keep Mother Earth
            healthy through our small efforts. You have junk? We have the time
            to take it from your place at your suitable time and date. The best
            part is, you will get beautiful trees and gardening products in
            exchange for your scrap.
          </p>
        </div>
      </div>

      <div id="services" className="services-cont">
        <h3>Services</h3>
      </div>

      <div className="slides">
        {/* Service 1 */}
        <div className="box-1">
          <div className="img-div">
            <img src={dustbin} alt="Scrap Collection" />
          </div>
          <div id="change-font" className="curve-div">
            <h3 className="h3-services">Scrap Collection</h3>
            <p>
              We collect scrap from your doorstep at your convenience. You will
              get plants in exchange for scraps. You can also ask for money if
              you prefer.
            </p>
          </div>
        </div>
        {/* Service 2 */}
        <div className="box-1">
          <div className="img-div">
            <img src={gardener} alt="Gardening Services" />
          </div>
          <div id="change-font" className="curve-div">
            <h3 className="h3-services">Gardening Services</h3>
            <p>
              We offer regular gardener services for the maintenance of your
              garden spaces, helping to transform them into vibrant landscapes.
            </p>
          </div>
        </div>
        {/* Service 3 */}
        <div className="box-1">
          <div className="img-div">
            <img src={tree} alt="Plants Nutrients" />
          </div>
          <div id="change-font" className="curve-div">
            <h3 className="h3-services">Plants Nutrients</h3>
            <p>
              Avail regular plant nutrients to keep your plants healthy. Even
              without scrap, you can avail these services at a nominal fee.
            </p>
          </div>
        </div>
        {/* Service 4 */}
        <div className="box-1">
          <div className="img-div">
            <img src={decoration} alt="Corporate Tie-Up" />
          </div>
          <div id="change-font" className="curve-div">
            <h3 className="h3-services">Corporate Tie-Up</h3>
            <p>
              We offer regular scrap collection and interior decoration services
              for corporate offices using plants, along with bio-waste
              management.
            </p>
          </div>
        </div>
      </div>

      <div id="valuate" className="valuate-div">
        <div className="plant-img"></div>

        <div className="valuate-para">
          <h3>
            <span style={{ color: "rgb(209, 154, 27)" }}>HOW</span> WE VALUATE
          </h3>
          <p>
            At DIN, we understand the importance of valuing scrap materials
            fairly and transparently. Our valuation process takes into account
            various factors such as the type and quantity of materials, current
            market prices, and the condition of the items. We strive to offer
            competitive rates to our customers while also considering the
            environmental impact of recycling. Our team of experts meticulously
            assesses each item to ensure accurate and honest valuations. With
            our commitment to integrity and sustainability, you can trust that
            you'll receive a fair value for your scrap materials when you choose
            DIN. &nbsp;&nbsp;
            <span>
              <Link className="changepage" to="/ratelist">
                Click here for detailed scrap ratelist.
              </Link>
            </span>
          </p>
        </div>
      </div>

      <div id="supplies" className="supplies-div">
        <div className="left-div">
          <h3>
            <span style={{ color: "rgb(209, 154, 27)" }}>PLANTS</span> AND
            SUPPLIES
          </h3>
          <p></p>
          <div className="dust">
            <p>
              At DIN, we believe in sustainability and giving back to the
              environment. As part of our commitment to eco-friendly practices,
              we offer a unique exchange program where you can trade your scrap
              materials for vibrant and lush plants. Not only does this
              initiative help reduce waste and promote recycling, but it also
              contributes to creating green spaces and improving air quality in
              our communities. Each plant we provide is carefully selected for
              its beauty and resilience, ensuring that you receive not only a
              valuable addition to your space but also a symbol of our shared
              dedication to a greener future.&nbsp;&nbsp;
              <span>
                <Link className="changepage" to="/plant">
                  Check our plant list.
                </Link>
              </span>
            </p>
          </div>
        </div>
        <div className="right-div"></div>
      </div>

      {/* Review Carousel Section */}
      <div className="review-carousel">
        <h3 className="footer-h3">What Our Customers Say</h3>
        {reviews.length > 0 ? (
          <Carousel
            showArrows={false} // Disable next/previous buttons
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            {reviews.map((review, index) => (
              <div key={index} className="carousel-review-item">
                <p>{review.review}</p>

                {/* Display star rating */}
                <div className="star-rating-display">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`star ${
                        review.rating >= star ? "filled" : ""
                      }`}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <p>No reviews yet. Be the first to leave a review!</p>
        )}
      </div>

      <div className="footer">
        <div className="footer-right">
          <h3>
            JOIN US
            <Tooltip
              title="By Joining Us You Can Be Our Regular Customer"
              position="top"
              trigger="mouseenter"
            >
              <img className="warning" src={warning} alt="warning-sign" />
            </Tooltip>
          </h3>

          <form onSubmit={handleSubmit}>
            <div className="name-input">
              <input
                type="text"
                name="name"
                placeholder="Name"
                onChange={handleName}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleEmail}
                required
              />
            </div>
            <div id="contact" className="contact-input">
              <input
                type="number"
                name="contact"
                placeholder="Contact"
                onChange={handlePhone}
                required
              />
              <button id="join-btn" type="submit">
                Join us
              </button>
            </div>
          </form>

          <Modal
            className="react-modal"
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Thank you for registering with us"
            closeTimeoutMS={200} // Add a close transition duration
            ariaHideApp={false} // For accessibility
          >
            <div className="modal-content">
              <h2>Thank you for joining us!</h2>
              <p>You are now part of Dump It Now.</p>
              <button onClick={() => setModalIsOpen(false)}>Close</button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Home;

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Order from "./components/Order";
import Ratelist from "./components/Ratelist";
import Plant from "./components/Plant";
import Cart from "./components/Cart";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import Checkout from "./components/Checkout";
import Payment from "./components/Payment";
import Supplies from "./components/Supplies";
import InfoPage from "./components/InfoPage";
import Dashboard from "./components/Dashboard";
import ReviewPage from "./components/ReviewPage";
import ScrollToTop from "./components/ScrollToTop"; // Add ScrollToTop component for scroll behavior
import "./styles/App.css";

function App() {
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]); // Cart state

  const cartCount = cart.length;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop /> {/* Scroll to top on route change */}

        {loading ? (
          <Loading />
        ) : (
          <>
            <Navbar cartCount={cartCount} /> {/* Navbar for all pages */}

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/orderpage" element={<Order />} />
              <Route path="/ratelist" element={<Ratelist />} />
              <Route path="/payment" element={<Payment />} />

              {/* Supplies and Plant Pages use cart and setCart */}
              <Route
                path="/supplies"
                element={<Supplies cart={cart} setCart={setCart} />}
              />
              <Route
                path="/plant"
                element={<Plant cart={cart} setCart={setCart} />}
              />

              {/* Cart and Checkout pages */}
              <Route
                path="/cart"
                element={<Cart cart={cart} setCart={setCart} />}
              />
              <Route
                path="/checkout"
                element={<Checkout cart={cart} setCart={setCart} />} // Pass setCart here
              />

              {/* Info Pages */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/faq" element={<InfoPage page="FAQ" />} />
              <Route path="/privacy-policy" element={<InfoPage page="Privacy Policy" />} />
              <Route path="/terms-of-use" element={<InfoPage page="Terms of Use" />} />

              {/* Review Page */}
              <Route path="/review-page" element={<ReviewPage />} />
            </Routes>

            <Footer /> {/* Footer for all pages */}
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;

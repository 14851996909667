import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate to redirect
import { firestore } from "../firebase/firebase"; // Assuming your Firebase setup is here
import "../styles/ReviewPage.css"; // Add your styles here
import Modal from "react-modal"; // Ensure Modal is imported
import "../styles/modal.css";

const ReviewPage = () => {
  const [userReview, setUserReview] = useState(""); // Track user's review input
  const [rating, setRating] = useState(0); // Track user's rating input
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to control modal visibility
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Handle the submission of a review
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add review and rating to Firestore
      await firestore.collection("reviews").add({
        review: userReview,
        rating: rating, // Save the rating
        timestamp: new Date(), // Timestamp for ordering
      });
      setHasSubmitted(true); // Mark user as having submitted a review
      setUserReview(""); // Clear the input field
      setRating(0); // Reset the rating
      setModalIsOpen(true); // Open the modal
    } catch (error) {
      console.error("Error adding review:", error);
    }
  };

  // Function to handle star rating
  const handleStarClick = (value) => {
    setRating(value);
  };

  // Function to handle closing the modal and navigating back to the home page
  const closeModal = () => {
    setModalIsOpen(false);
    navigate("/"); // Redirect to home page
  };

  return (
    <div className="review-page">
      <h2 style={{ marginTop: "4rem" }}>Customer Reviews</h2>

      {/* Form for submitting a review */}
      <form onSubmit={handleReviewSubmit} className="review-form">
        <textarea
          value={userReview}
          onChange={(e) => setUserReview(e.target.value)}
          placeholder="Write your review here"
          required
          rows="4"
          className="review-input"
        />

        {/* 5-Star Rating */}
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={`star ${rating >= star ? "filled" : ""}`}
              onClick={() => handleStarClick(star)}
            >
              ★
            </span>
          ))}
        </div>

        <button type="submit" className="submit-btn">
          Submit Review
        </button>
      </form>

      {/* Modal for thank you message */}
      <Modal
        className="react-modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Thank You"
        ariaHideApp={false} // Prevents the app from being hidden behind modal
      >
        <h2>Thank you for reviewing us!</h2>
        <p>Your feedback is valuable to us.</p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default ReviewPage;

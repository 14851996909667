import React from "react";
import { useLocation } from "react-router-dom";
import "../styles/InfoPage.css";

const InfoPage = () => {
  const location = useLocation();

  const renderContent = () => {
    switch (location.pathname) {
      case "/faq":
        return (
          <div className="content-container">
            <h2 className="section-title">Frequently Asked Questions (FAQ)</h2>
            <div className="faq-section">
              <div className="faq-item">
                <h3 className="faq-question">What is Dumpitnow?</h3>
                <p className="faq-answer">
                  Dumpitnow is an online platform that allows users to exchange
                  scrap materials like paper, plastic, metal, and electronic
                  waste for plants and other eco-friendly supplies.
                </p>
              </div>

              <div className="faq-item">
                <h3 className="faq-question">How can I schedule a pickup?</h3>
                <p className="faq-answer">
                  You can schedule a pickup by filling out our form with your
                  details, including the date and time for pickup. Once your
                  request is confirmed, we will arrange the collection of your
                  scrap materials.
                </p>
              </div>

              <div className="faq-item">
                <h3 className="faq-question">What materials do you accept?</h3>
                <p className="faq-answer">
                  We accept paper, plastic, metal, and e-waste materials. You
                  can check our price list or contact us for specific items not
                  listed.
                </p>
              </div>

              <div className="faq-item">
                <h3 className="faq-question">
                  Is there a minimum amount of scrap required for pickup?
                </h3>
                <p className="faq-answer">
                  Yes, we require a minimum of 15kg of scrap for pickup. If the
                  total is below this amount, pickup charges may apply.
                </p>
              </div>

              <div className="faq-item">
                <h3 className="faq-question">
                  What do I receive in exchange for my scrap?
                </h3>
                <p className="faq-answer">
                  You will receive plants, coupons, or other eco-friendly
                  products based on our valuation of the scrap materials you
                  provide.
                </p>
              </div>
            </div>
          </div>
        );
      
      case "/privacy-policy":
        return (
          <div className="content-container">
            <h2 className="section-title">Privacy Policy</h2>
            <p className="content-text">
              Dumpitnow is a online platform where we facilitate the exchange of
              paper, plastic, metal &amp; electronic scrap materials with plants
              and supplies. We understands the importance of privacy and also
              the importance of maintaining the confidentiality of personal
              information. Our Privacy Policy is applied to all the products and
              services which are being provided by us, and also sets out how we
              may collect, disclose and use the personal information (name,
              address,etc.) of users. Users may use our services and products
              via Website. <br />
              By using our website, you hereby consent to our Privacy policy and
              agree to its Terms and conditions.
            </p>
            <h3>Collection of Information</h3>
            <li>
              Your privacy is important to us and we have taken steps to ensure
              that we don&#39;t collect more information from you than is
              necessary for you to utilize our services and to protect your
              account.
            </li>
            <li>
              We may collect your personal information at the time of
              registration like- name, address, mobile number, phone number, and
              the location of the user on the Website
            </li>
            <li>
              We record &amp; maintain the activities which are being performed
              by the users on the site.
            </li>

            <li>
              During the registration users need to provide the mandatory
              information in certain categories like- personal data (name,
              contact number, address, etc. If the user does not provide the
              mandatory information then we may not be able to complete the
              process of registration and then we will also not be able to
              deliver our services to the users.
            </li>

            <h3>Use of Personal Data</h3>
            <p>
              If the user is providing us any Personal Data then that means the
              user is giving us rights to use their information for the
              following purposes:
            </p>
            <li>Verifying the identity of users.</li>
            <li>
              Verifying the eligibility to register as a user of this Site;
            </li>
            <li>
              We will provide you a customer service that will respond to your
              queries, feedback, claims, or disputes
            </li>
            <li>
              We may use your name, phone number, residential address, and email
              address (all marketing data) to provide notices, surveys, product
              alerts, communications, and other marketing materials to you
              relating to goods and services which are offered by us on the
              Site.
            </li>
            <li>
              This disclosure is being done as it may be required for any of the
              above purposes or as required by law or in respect of any claims
              or potential claims brought against us.
            </li>
            <h3>Disclosure of Personal Data</h3>
            <li>
              When necessary, we may also disclose and transfer users&#39;
              Personal Data to our advisers, law enforcement agencies, insurers,
              government, and other organizations.
            </li>
            <li>
              Any Personal Data supplied by the user will be retained by us and
              will be accessible by our employees, any Service Providers engaged
              by us, and third parties.
            </li>
            <li>
              We have implemented security controls and associated procedures to
              keep your Account Information and other Personal Data secure from
              malicious users or intruders. These controls provide a reasonable
              assurance that the minimum-security requirements are in place.
              These controls do not guarantee that such controls can eliminate
              all of the risks of data- theft, data loss, or misuse of data.
            </li>
            <h3>Cookies</h3>
            <p>
              We use “cookies” to store specific information about users and
              also track the user’s visits to the Site. A “cookie” is a small
              amount of data that is sent to the user’s browser and stored on
              the user’s system hard drive. A cookie can be sent to the user’s
              system hard drive only if the user will access the site using that
              system. If the user does not de-activate or erase the cookie, each
              time user uses the same computer to access the Site, our web
              servers will be notified of the user’s visit to the Site and in
              turn, we may have knowledge of the user’s visit and the pattern of
              your usage. Generally, we use cookies to identify users and also
              enable us to assist our partners to track user visits to the Site.
            </p>
            <h3>Changes to this Privacy Policy</h3>
            <p>Any changes to this Privacy Policy will be communicated by us posting an amended and restated
Privacy Policy on the Site. You agree that any information we hold will be governed by the latest
version of the Privacy Policy.</p>

          </div>
        );
      case "/terms-of-use":
        return (
          <div className="content-container">
            <h2 className="section-title">Terms &amp; Conditions</h2>

            <p className="content-text">
              <br />
              <li>
                Please read the terms and condition carefully which is located
                on the website. The terms, guidelines, privacy policy, and other
                documents are made available by the website from time to time.
                In the case of any conflict between these terms or documents,
                the term will have an overriding effect. This agreement sets out
                the legally binding agreement between the user of the website
                and Dumpitnow. We understands the importance of privacy and also
                the importance of maintaining the confidentiality of personal
                information. Our Privacy Policy is applied to all the products
                and services which are being provided by us, and also sets out
                how we may collect, disclose and use the personal information
                (name, address, etc.) of users. Users may use our services and
                products via Website, or by calling the Customer Support Team.
                By using our website, you hereby consent to our Privacy policy
                and agree to its Terms and conditions.
              </li>
              <li>
                By accessing or using the website in any manner like visiting,
                browsing, or surfing the site you agree to be bound by these
                terms.
              </li>
              <li>
                These terms and rights (any) may not be transferred or assigned
                by the user but may be assigned by the website without
                restriction. Any attempted transfer or assignment in violation
                shall be null or void.
              </li>
              <br />
              <h3>Acceptance of Terms of Use </h3>
              <li>
                By using or by browsing the website you are agreeing or
                accepting the terms, as amended from time to time with or
                without the notice.
              </li>
              <li>
                This website is owned and operated by Dumpitnow and it reserves
                the right to modify or discontinue, temporarily or permanently
                at any time with or without notice. You agree that the website
                shall not be liable to you or to any third for any suspension or
                discontinuance of the website services.{" "}
              </li>
              <li>
                Dumpitnow or the website management may modify these terms and
                conditions from time to time and these changes would be
                reflected on the website with the updated version of the terms
                or also may reflect in applications or to you via e-mail and you
                agree to be bound to the changes of these terms when you use our
                website or website services.
              </li>
              <li>
                4. When you register an account on our website and upload,
                submit, and enter any information to the website then you shall
                be deemed to have agreed to and understand the terms.
              </li>
              <li>
                Website also uses cookies and by using the website you agree and
                give us consent for the use of cookies in accordance with the
                terms of the privacy policy.
              </li>
              <br />
              <span style={{ fontWeight: "bold" }}>
                IF YOU DO NOT AGREE TO THESE TERMS OF USE, THEN YOU MAY NOT USE
                THE WEBSITE{" "}
              </span>
              <br />
              <h3>Services</h3>
              <li>
                Dumpitnow is operated in the scrap industry which means that it
                deals in location, transportation, commerce, treatment,
                recycling, and exploitation of the scrap materials such as
                metal, plastic, paper, and e-waste among others. It provides its
                visitor an online platform that allows the users to sell
                directly and execute deals that are offered by us from time to
                time.
              </li>
              <li>
                For our pickup service, you must have to provide your accurate
                information like name, address, mobile number, etc. Your pickup
                will be held on the scheduled date as provided by you while
                submitting the request. You can reschedule or cancel your pickup
                request at any time after submitting your request. You must have
                15Kg or more scrap for pickup, otherwise, pickup charges may
                apply. The rate of all materials is fixed as mentioned on our
                price list. For the rates of other materials that are not listed
                on the list then you can contact us. If there are any unforeseen
                circumstances, we can also reschedule your pickup request to
                another working day.
              </li>
              <li>
                The website is a service-based platform, the sole purpose of
                which is to provide a platform, where the usrers of the products
                related to the scrap industry may interact and strike a deal of
                sale. Neither Dumpitnow or its website shall have absolute
                liabilities, responsibilities, or obligations with regards to
                any item, sold through the website.
              </li>
              <li>
                Dumpitnow will not bring about any obligation of all nature
                emerging out of any transaction made by the seller through the
                website in the sale of any item.
              </li>
              <h3>Cancellation Policy for Pickups</h3>
              Accepting or canceling the scrap pick-up service depends upon
              whether the services are approachable to the user or not and
              whether the service boy is picking up the scrap from the location
              which is accessible. Dumpitnow has its rights in which it can
              accept or refuse the request initiated by the user. A user can
              also start the request or cancel the request as per their
              requirements and for this, no charges will be applicable. Once the
              service boy reaches the location and collected the scrap then the
              user cannot cancel the request and cannot ask for the products
              &amp; goods back. The website, its logos, and its other registered
              trademarks belong to the company , the website, and the management
              of the website give no permission to you for the use of these
              trademarks and such use may constitute an infringement of
              Intellectual Property Rights. Dumpitnow hereby reserves the right
              to update, modify, change, amend, terminate or discontinue the
              Website, the terms, and policy at any time and at its sole and
              final discretion. Dumpitnow may change the website’s
              functionalities and applicable changes at any time. Any changes to
              these terms will be displayed on the website and we may also
              notify you through the website.
            </p>
          </div>
        );
      default:
        return (
          <div className="content-container">
            <h2 className="section-title">Page Not Found</h2>
            <p className="content-text">
              The page you are looking for doesn't exist.
            </p>
          </div>
        );
    }
  };

  return <div className="info-page">{renderContent()}</div>;
};

export default InfoPage;

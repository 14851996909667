import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../firebase/firebase.js";
import "../styles/checkout.css";

const Checkout = ({ cart = [], setCart }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "", // Add phone number to form data
    address: "",
    city: "",
    state: "",
    zip: "",
  });
  const [orderConfirmation, setOrderConfirmation] = useState("");

  // Group cart items by name and calculate total price
  const groupedCart = cart.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.name === item.name);
    const itemPrice =
      typeof item.price === "string"
        ? parseFloat(item.price.replace(/[₹\s]/g, ""))
        : item.price;
    if (existingItem) {
      existingItem.quantity += 1;
      existingItem.totalPrice += itemPrice;
    } else {
      acc.push({ ...item, quantity: 1, totalPrice: itemPrice });
    }
    return acc;
  }, []);

  const totalAmount = groupedCart.reduce(
    (total, item) => total + item.totalPrice,
    0
  );

  // Update form data on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle Proceed to Payment button click
  const handleProceedToPayment = async () => {
    try {
      await firestore.collection("orders").add({
        ...formData,
        cart: groupedCart,
        totalAmount,
        createdAt: new Date(),
      });

      setOrderConfirmation(
        `Order has been placed! You will receive your order soon. Total amount to be paid at delivery: ₹${totalAmount}`
      );
      setCart([]); // Clear the cart
      setTimeout(() => {
        navigate("/"); // Navigate to the home page after 3 seconds
      }, 3000);
      
      // Clear form data after placing the order
      setFormData({
        name: "",
        email: "",
        phone: "", // Reset phone
        address: "",
        city: "",
        state: "",
        zip: "",
      });
      
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>

      <div className="cart-summary">
        <h3>Cart Summary</h3>
        <ul className="cart-items">
          {groupedCart.map((item, index) => (
            <li key={index} className="cart-item">
              <img
                src={item.image}
                alt={item.name}
                className="cart-item-image"
              />
              <div className="cart-item-details">
                <h4>{item.name}</h4>
                <p>
                  <strong>Price:</strong> ₹{item.price} x {item.quantity} = ₹
                  {item.totalPrice.toFixed(2)}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <div className="cart-total">
          <h3>Total Amount: ₹{totalAmount.toFixed(2)}</h3>
        </div>
      </div>

      <div className="address-form">
        <h3>Shipping Address</h3>
        <form>
          <label>
            Full Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Phone Number: {/* Add phone number input */}
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Address:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            City:
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            State:
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Zip Code:
            <input
              type="text"
              name="zip"
              value={formData.zip}
              onChange={handleInputChange}
              required
            />
          </label>
          <button
            type="button"
            className="proceed-payment-btn"
            onClick={handleProceedToPayment}
          >
            Confirm Order
          </button>
        </form>
      </div>

      {orderConfirmation && (
        <div className="order-confirmation">
          <p>{orderConfirmation}</p>
        </div>
      )}
    </div>
  );
};

export default Checkout;
